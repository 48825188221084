/*
 * @Author: Gang Jiang 
 * @Date: 2024-12-02 09:38:00 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2024-12-02 11:02:07
 */

 <template>
  <a-space>
    <a-space-compact>
      <a-button @click="onClick('toFirst')" :disabled="status.toFirst">
        <i class="iconfont icon-jiantou-zuo-shuang-cu" />
      </a-button>
      <a-button @click="onClick('pre')" :disabled="status.pre">
        <i class="iconfont icon-jiantou-zuo-cu" />
      </a-button>
      <a-button @click="onClick('next')" :disabled="status.next">
        <i class="iconfont icon-jiantou-you-cu" />
      </a-button>
      <a-button @click="onClick('toLast')" :disabled="status.toLast">
        <i class="iconfont icon-jiantou-you-shuang-cu" />
      </a-button>
    </a-space-compact>
  </a-space>
</template>
<script setup>
import { reactive, watch } from 'vue'

const { pagination } = defineProps(['pagination'])
const status = reactive({
  toFirst: true,
  pre: true,
  next: false,
  toLast: false,
})

watch(
  () => pagination,
  (val) => {
    const { total, size, current } = val
    let endPage = Math.ceil(total / size)
    if (total <= size) {
      //只有一页
      status.pre = true
      status.toFirst = true
      status.next = true
      status.toLast = true
      return
    }
    if (current == endPage) {
      status.next = true
      status.toLast = true
      status.pre = false
      status.toFirst = false
    } else if (current == 1) {
      status.pre = true
      status.toFirst = true
      status.next = false
      status.toLast = false
    } else {
      status.pre = false
      status.toFirst = false
      status.next = false
      status.toLast = false
    }
  }
)
const emit = defineEmits(['pageChange'])

const onClick = (direction) => {
  const { total, size, current } = pagination
  let endPage = Math.ceil(total / size)

  if ((direction == 'toFirst' || direction == 'pre') && current == 1) {
    return
  }

  if ((direction == 'next' || direction == 'toLast') && current == endPage) {
    return
  }

  let currPage = current

  switch (direction) {
    case 'pre':
      status.pre = true
      currPage--
      break
    case 'next':
      status.next = true
      currPage++
      break
    case 'toLast':
      status.toLast = true
      currPage = endPage
      break
    default:
      status.toFirst = true
      currPage = 1
      break
  }

  emit('pageChange', currPage)
}
</script>
