/*
 * @Author: Gang Jiang 
 * @Date: 2024-11-25 15:25:01 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2024-12-02 14:02:00
 */

<template>
  <div class="product">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" />
    <div class="content-wrapper">
      <div class="left"></div>
      <div class="right">
        <div class="left-filter">
          <div class="item seperates categories">
            <h3 @click="setCategory">All Categories</h3>
            <a-tree
              :tree-data="categories"
              height="280"
              @select="setCategory"
            />
          </div>
          <div class="item seperates brands">
            <h3>Brands</h3>
            <a-input placeholder="Search Brand..." />
            <div class="brands-list">
              <a-checkbox
                class="brand-item"
                v-for="brand in brands"
                :key="brand.title"
              >
                {{ brand.title }}</a-checkbox
              >
            </div>
          </div>
          <div class="item commission mb10">
            <h3>Commission Min</h3>
            <a-input
              :min="0"
              :max="100"
              class="width100"
              placeholder="Please enter..."
              @keyup="handleCommissionInput"
              v-model:value="filter.commission"
              :parser="(value) => value.replace('%', '')"
            >
              <template #suffix>
                <i class="percent">%</i>
                <div class="ant-input-number-handler-wrap">
                  <span
                    unselectable="on"
                    role="button"
                    aria-label="Increase Value"
                    aria-disabled="false"
                    class="ant-input-number-handler ant-input-number-handler-up"
                    ><span
                      role="img"
                      aria-label="up"
                      class="anticon anticon-up ant-input-number-handler-up-inner"
                      ><svg
                        focusable="false"
                        data-icon="up"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        viewBox="64 64 896 896"
                      >
                        <path
                          d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3A8 8 0 00140 768h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z"
                        ></path></svg></span></span
                  ><span
                    unselectable="on"
                    role="button"
                    aria-label="Decrease Value"
                    aria-disabled="false"
                    class="ant-input-number-handler ant-input-number-handler-down"
                    ><span
                      role="img"
                      aria-label="down"
                      class="anticon anticon-down ant-input-number-handler-down-inner"
                      ><svg
                        focusable="false"
                        data-icon="down"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        viewBox="64 64 896 896"
                      >
                        <path
                          d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"
                        ></path></svg></span
                  ></span>
                </div>
              </template>
            </a-input>
          </div>
          <div class="item availability mb10">
            <h3>Availability</h3>
            <a-select
              class="width100"
              :options="availabilityOptions"
              v-model:value="filter.availability"
              @select="(id, option) => setFilter('availability', option)"
            />
          </div>
          <div class="item mb10 price">
            <h3>Price</h3>
            <a-select
              class="width100"
              :options="priceOptions"
              v-model:value="filter.price"
              @select="(id, option) => setFilter('price', option)"
            />
          </div>
        </div>
        <div class="right-content">
          <div class="top">
            <h2>{{ filter.categoryName }}</h2>
            <div class="top-filter">
              <top-pagination
                :pagination="{
                  total: productCount,
                  size: filter.size,
                  current: filter.page,
                }"
                @pageChange="(page) => setFilter('page', page)"
              />
              <a-select />
              <div class="in-stock">
                <a-checkbox v-model:checked="filter.inStockOnly"
                  >In Stock only</a-checkbox
                >
              </div>
              <artmisads-select
                v-model:value="filter.sort"
                style="width: 200px"
                @change="handleChangeSellers"
              >
                <a-select-option :value="1">Best Sellers</a-select-option>
                <a-select-option :value="2">Newly Added</a-select-option>
                <a-select-option :value="3">Commission</a-select-option>
                <a-select-option :value="4">Price</a-select-option>
              </artmisads-select>
            </div>
          </div>
          <a-spin :spinning="isLoadingList">
            <div class="list">
              <a
                class="item"
                :href="'/#/products/' + product.id"
                target="_blank"
                v-for="product in productList"
                :key="product.id"
              >
                <div class="img-wrapper">
                  <img class="logo" :src="product.imageUrl" />
                </div>
                <p class="price">
                  {{ product.currencySymbol }}{{ product.price
                  }}<i class="ratio">{{ product.commission }}%</i>
                </p>
                <p class="title">
                  {{ product.title }}
                </p>
                <div class="star">
                  <a-rate
                    :value="product.productStarRating"
                    allow-half
                    disabled
                  />{{ product.productNumRatings }}
                </div>
              </a>
            </div>
            <a-pagination
              class="pagination"
              v-if="productCount > filter.size"
              v-model:current="filter.page"
              :pageSize="filter.size"
              @change="setPage"
              :total="productCount"
              show-less-items
            />
          </a-spin>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { publisherApi } from '../../../server/index'
import breadcrumbs from '../../admin/breadcrumbs.vue'
import { CURRENCY_MAPPER } from '../../../contants/common'
import { AVAILABILITY, PRICE } from '../../../contants/publisher'
import TopPagination from '../../../components/TopPagination.vue'

const countryCode = 'US'
const breadcrumbsData = {
  title: 'Products Catelogue',
  des: 'Find the products you want to promote!',
  links: [
    {
      name: 'Home',
      path: '/#/publisher/products',
    },
    {
      name: 'Products',
      path: '/#/publisher/products',
    },
  ],
}

const filter = ref({
  page: 1,
  size: 10,
  price: 'All',
  minPrice: null,
  maxPrice: null,
  keywords: null,
  brandName: null,
  categoryId: null,
  commission: null,
  inStockOnly: null,
  countryCode: 'US',
  availability: 'All',
  categoryLevel: null,
  sort: '-bestSellers',
  categoryName: 'All Categories',
})
const productCount = ref(0)
const brands = ref([])
const categories = ref([])
const productList = ref([])
const isLoadingList = ref(false)
const isLoadingCategory = ref(false)
const availabilityOptions = AVAILABILITY.map((item) => {
  return {
    label: item,
    value: item,
  }
})
const priceOptions = PRICE.map((item) => {
  return {
    ...item,
    label: item.label.replace(/%s/g, CURRENCY_MAPPER[countryCode]),
    value: item.label,
  }
})

publisherApi
  .getFirstLevelCategory({ countryCode: filter.value.countryCode })
  .then((res) => {
    if (res.success) {
      categories.value = res.data.map((item) => {
        return {
          level: 1,
          key: item.categoryId,
          title: item.fullName,
        }
      })
    }
  })

publisherApi
  .getBrandsList({ countryCode: filter.value.countryCode })
  .then((res) => {
    if (res.success) {
      brands.value = res.data.map((item) => {
        return {
          title: item.amazonBrandName,
        }
      })
    }
  })

function getProductList() {
  const params = { ...filter.value }

  Object.keys(params).forEach((key) => {
    if (
      ['price', 'availability', 'categoryName'].indexOf(key) != -1 ||
      ['', null, undefined].indexOf(params[key]) != -1
    ) {
      delete params[key]
    }
  })

  isLoadingList.value = true
  publisherApi.getProductList(params).then((res) => {
    isLoadingList.value = false
    if (filter.value.page == 1) {
      productCount.value = res.total
    }

    productList.value = res.data
  })
}

getProductList()

function setFilter(type, option) {
  if (type == 'availability') {
    if (option.value == 'All') {
      filter.value.inStock = null
    } else {
      filter.value.inStock = option.value === 'In Stock'
    }

    filter.value.page = 1
  } else if (type == 'price') {
    // price
    filter.value.minPrice = option.min || null
    filter.value.maxPrice = option.max || null
    filter.value.page = 1
  } else if (type == 'page') {
    filter.value.page = option
  }

  getProductList()
}

function setCategory(id, category) {
  if (category) {
    const { key, title, level } = category.selectedNodes[0]
    filter.value.categoryId = key
    filter.value.categoryName = title
    filter.value.categoryLevel = level
    getCategoryList(category.selectedNodes[0], key)
  } else {
    filter.value.categoryId = null
    filter.value.categoryLevel = null
    filter.value.categoryName = 'All Categories'
  }

  getProductList()
}

function getCategoryList(currentTreeNode, categoryId) {
  isLoadingCategory.value = true
  publisherApi
    .getCategoryList({ countryCode: filter.value.countryCode, id: categoryId })
    .then((res) => {
      isLoadingCategory.value = false
      if (res.success) {
        currentTreeNode.dataRef.children = res.data.map((item) => {
          return {
            level: item.level,
            key: item.categoryId,
            title: item.fullName,
          }
        })
      }
    })
}

function setPage(page) {
  filter.value.page = page
  getProductList()
}

function handleCommissionInput(e) {
  e.target.value = e.target.value.replace(/^0+/, '').replace(/\D/g, '')
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/ellipsis.less';

.list {
  display: grid;
  grid-gap: 20px;
  margin-bottom: 20px;
  grid-template-columns: repeat(4, 1fr);
  .item {
    padding: 10px;
    color: #333;
    border-radius: 10px;
    text-decoration: none;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
    &:hover {
      .title {
        color: #7d58e5;
      }
    }
  }
  .img-wrapper {
    height: 0;
    width: 100%;
    position: relative;
    text-align: center;
    padding-bottom: 100%;
  }
  .logo {
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  .price {
    display: flex;
    color: #999;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
    > i {
      font-style: normal;
    }
  }
  .title {
    margin: 10px 0;
    max-height: 48px;
    text-align: justify;
    .multiple-ellipsis(2);
  }
  .star {
    display: flex;
    color: #999;
    align-items: center;
    justify-content: space-between;
    :deep(.ant) {
      &-rate {
        margin-top: -2px;
        margin-right: 10px;
      }
    }
  }
}
.right {
  display: flex;
  :deep(.ant) {
    &-pagination {
      text-align: right;
      margin-bottom: 20px;
    }

    &-input-suffix {
      .percent {
        margin-inline-end: 17px;
      }
    }
    &-input-number {
      &-handler {
        height: 50%;
        overflow: hidden;
        color: rgba(0, 0, 0, 0.45);
        font-weight: bold;
        line-height: 0;
        text-align: center;
        cursor: pointer;
        border-inline-start: 1px solid #d9d9d9;
        transition: all 0.2s linear;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: auto;
        height: 40%;
        &-wrap {
          position: absolute;
          inset-block-start: 0;
          inset-inline-end: 0;
          width: 22px;
          height: 100%;
          background: #ffffff;
          border-start-start-radius: 0;
          border-start-end-radius: 6px;
          border-end-end-radius: 6px;
          border-end-start-radius: 0;
          opacity: 1;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          transition: opacity 0.2s linear 0.2s;
        }
        &-up,
        &-down {
          &:hover {
            height: 60%;
          }
          &-inner {
            display: inline-flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.45);
            font-style: normal;
            line-height: 0;
            font-size: 7px;
            text-align: center;
            text-transform: none;
            vertical-align: -0.125em;
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transition: all 0.2s linear;
            user-select: none;
          }
        }
        &-down {
          border-block-start: 1px solid #d9d9d9;
          border-end-end-radius: 6px;
        }
      }
    }
  }
}
.left-filter {
  flex: 0 0 200px;
  margin-right: 20px;
  :deep(.ant) {
    &-tree-switcher {
      width: 10px;
    }
  }
  h3 {
    font-size: 14px;
    font-weight: normal;
  }
  .categories > h3 {
    cursor: pointer;
  }
  .brands-list {
    margin-top: 6px;
    overflow-y: auto;
    max-height: 280px;
  }
  .brand-item {
    height: 30px;
    display: flex;
    line-height: 30px;
    font-weight: normal;
  }
  .seperates {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
  }
  .width100 {
    width: 100%;
  }
  .brands {
    padding-bottom: 20px;
  }
  .price {
    margin-bottom: 20px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
}
</style>