export const CURRENCY_MAPPER = {
  US: {
    host: 'amazon.com',
    currency: '$'
  },
  UK: {
    host: 'amazon.co.uk',
    currency: '£'
  },
  DE: {
    host: 'amazon.de',
    currency: '€'
  },
  DE: {
    host: 'amazon.de',
    currency: '€'
  },
  CA: {
    host: 'amazon.ca',
    currency: '$'
  },
  FR: {
    host: 'amazon.com',
    currency: '€'
  },
}